import React from 'react'

const IPacketOnlyFolderIcon = props => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 96.2 96.2">
    <defs>
      <style>
        {`
          .st0 {
            fill: none;
          }

          .st1 {
            fill: lightgray;
          }
          `}
      </style>
    </defs>
    <rect class="st0" x="-83.3" y="-59.7" width="719" height="215.7" />
    <g>
      <path
        class="st1"
        d="M48.2,0C21.7,0,0,21.4,0,47.9c0,26.7,21.4,48.3,47.9,48.4,26.6,0,48.1-21.5,48.1-48.2C96.1,21.7,74.6,0,48.3,0h0ZM48.1,89.7c-22.9,0-41.5-18.6-41.5-41.5,0-22.9,18.6-41.6,41.4-41.6,22.9,0,41.5,18.6,41.5,41.5,0,22.9-18.6,41.6-41.4,41.6h0Z"
      />
      <path
        class="st1"
        d="M73.9,32.9h-26.1c-.5,0-.9-.4-.9-.9v-1.9c0-.6-.5-1.1-1.1-1.1h-18.3c-.6,0-1.1.5-1.1,1.1v1.9c0,.5-.4.9-.9.9h-2.6c-.1,0-.3,0-.4,0,0,0-.2,0-.2.1,0,0-.2.1-.2.1,0,0-.1.1-.2.2,0,.1-.2.2-.2.3,0,0,0,0,0,0,0,.2-.1.4-.1.7l1.3,32c0,.9.8,1.6,1.8,1.6h47.8c.9,0,1.7-.7,1.8-1.6l1.7-32c0-.9-.7-1.7-1.6-1.7h0Z"
      />
    </g>
  </svg>
)
export default IPacketOnlyFolderIcon
