import React from 'react'
import { connect } from 'react-redux'

import { NavLink, Link } from 'react-router-dom'
import APPRAISAL_ACTIONS from '../../core/actions/appraisalActions.js'

import './value.scss'

import ValueNewAppraisal from './components/valueNewAppraisal'
import ValueHistoricAppraisals from './components/valueHistoricAppraisals'
import ValueAppraisal from './components/valueAppraisal'
import ValueSettings from './components/valueSettings'

import AppraisalIcon from './components/icons/appraisal-icon.js'
import IPacketValueIcon from './components/icons/ipacket-value-icon.js'

function ValueNavigation({ appraisalInfo }) {
  return (
    <div className="value-header-container">
      <div className="value-header value-section">
        <div className="value-header-title">
          <IPacketValueIcon />
        </div>
        <div className="value-header-links">
          <NavLink className="value-header-link" to="/value/new-appraisal">
            <div className="value-header-link-icon">
              <AppraisalIcon />
            </div>
            <div className="value-header-link-title">New Value</div>
          </NavLink>
          {appraisalInfo ? (
            <NavLink className="value-header-link" to={`/value/appraisal/${appraisalInfo.id}`}>
              <div className="value-header-link-icon">
                <AppraisalIcon />
              </div>
              <div className="value-header-link-title">Vehicle Appraisal</div>
            </NavLink>
          ) : (
            <React.Fragment />
          )}

          <NavLink className="value-header-link" to="/value/historic-appraisals">
            <div className="value-header-link-icon">
              <AppraisalIcon />
            </div>
            <div className="value-header-link-title">Historic Appraisals</div>
          </NavLink>
          <NavLink className="value-header-link" to="/value/settings">
            <div className="value-header-link-icon">
              <AppraisalIcon />
            </div>
            <div className="value-header-link-title">Settings</div>
          </NavLink>
        </div>
      </div>
    </div>
  )
}
function ValueSiteStyle() {
  return (
    <style>
      {`
        body .headerMainStyle .headerLinkStyle.headerTextStyle.active svg ,
        .headerMainStyle .headerLinkStyle.headerTextStyle.active svg,
        .headerMainStyle .headerUserButtonStyle:hover .arrow-down-icon
         {
          fill:#ff0051;
        }
        body .headerMainStyle .headerLinkStyle.headerTextStyle:hover svg,
        .headerMainStyle .headerLinkStyle.headerTextStyle:hover svg .g,
        .headerMainStyle .headerLinkStyle.headerTextStyle:hover svg .e,
        .headerMainStyle .headerLinkStyle.headerTextStyle:hover svg .d
        {
          fill:#ff0051;
        }
        .headerMainStyle .headerUserButtonStyle div .headerUserImgContainerStyle {
          background-color: #ff0051;
          border-color: #ff0051;
        }
        `}
    </style>
  )
}
function ValueBody({ match, appraisalInfo }) {
  let page = <ValueNewAppraisal />
  switch (match.params.section) {
    case 'appraisal':
      page = <ValueAppraisal />
      break
    case 'historic-appraisals':
      page = <ValueHistoricAppraisals />
      break
    case 'settings':
      page = <ValueSettings />
      break
  }
  return <div className="value-body-container">{page}</div>
}
function Value({ match, appraisalInfo }) {
  //TODO: Add URL Based Appraisal Options
  return (
    <div className="value-app-container">
      <div className="value-app">
        <ValueNavigation appraisalInfo={appraisalInfo} />
        <ValueBody match={match} appraisalInfo={appraisalInfo} />
        <ValueSiteStyle />
      </div>
    </div>
  )
}

const mapStatetoProps = state => ({
  appraisalInfo: state.value.appraisalInfo,
})
export default connect(mapStatetoProps)(Value)
