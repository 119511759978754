import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../helpers/loadingspinner'
import IPacketOnlyFolderIcon from './icons/ipacket-only-folder-icon.js'

import './valueAppraisal.scss'
import APPRAISAL_ACTIONS from '../../../core/actions/appraisalActions.js'
const decimalFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' })

function ValueAppraisal({ appraisalInfo, setAppraisalInfo }) {
  const [loadingAppraisal, setLoadingAppraisal] = useState(false)

  const [comparablesList, setComparablesList] = useState(null)

  const [tradeAdjustmentEditingEnabled, setTradeAdjustmentEditingEnabled] = useState(false)
  const [dealerRetailValueField, setDealerRetailValueField] = useState(
    appraisalInfo ? appraisalInfo.dealer_value.retail_value + '' : '',
  )
  const [reconditioningCostField, setReconditioningCostField] = useState(
    appraisalInfo ? appraisalInfo.dealer_value.reconditioning_cost + '' : '',
  )
  const [certificationCostField, setCertificationCostField] = useState(
    appraisalInfo ? appraisalInfo.dealer_value.certification_cost + '' : '',
  )
  const [bodyWorkField, setBodyWorkField] = useState(
    appraisalInfo ? appraisalInfo.dealer_value.body_work_cost + '' : '',
  )
  const [tiresField, setTiresField] = useState(
    appraisalInfo ? appraisalInfo.dealer_value.tire_cost + '' : '',
  )
  const [targetedGrossProfitField, setTargetedGrossProfitField] = useState(
    appraisalInfo ? appraisalInfo.dealer_value.gross_profit + '' : '',
  )
  const [otherField, setOtherField] = useState(
    appraisalInfo ? appraisalInfo.dealer_value.other_cost + '' : '',
  )

  useEffect(() => {
    if (appraisalInfo) {
      setTradeAdjustmentFields({
        dealerRetailValueField: appraisalInfo.dealer_value.retail_value + '',
        reconditioningCostField: appraisalInfo.dealer_value.reconditioning_cost + '',
        certificationCostField: appraisalInfo.dealer_value.certification_cost + '',
        bodyWorkField: appraisalInfo.dealer_value.body_work_cost + '',
        tiresField: appraisalInfo.dealer_value.tire_cost + '',
        targetedGrossProfitField: appraisalInfo.dealer_value.gross_profit + '',
        otherField: appraisalInfo.dealer_value.other_cost + '',
      })
      getComparablesList(appraisalInfo.id)
    }
  }, [appraisalInfo])

  useEffect(() => {
    const url = window.location.href
    const urlObj = new URL(url)
    const pathSegments = urlObj.pathname.split('/')
    const appraisalIndex = pathSegments.indexOf('appraisal')
    let appraisalId = pathSegments[appraisalIndex + 1] || null
    if (appraisalId) {
      appraisalId = appraisalId.split('?')[0]
      if (!appraisalInfo || (appraisalInfo && appraisalInfo.id !== appraisalId)) {
        retrieveAppraisal(appraisalId)
      }
    } else {
      window.location.href = '/value/new-appraisal'
    }
  }, [])

  const setTradeAdjustmentFields = fields => {
    setDealerRetailValueField(fields.dealerRetailValueField)
    setReconditioningCostField(fields.reconditioningCostField)
    setCertificationCostField(fields.certificationCostField)
    setBodyWorkField(fields.bodyWorkField)
    setTiresField(fields.tiresField)
    setOtherField(fields.otherField)
    setTargetedGrossProfitField(fields.targetedGrossProfitField)
  }
  const errorToast = message => {
    toast(message, { className: 'appraisal-display-error-toast', autoClose: false })
  }

  const getComparablesList = appraisalId => {
    APPRAISAL_ACTIONS.listAppraisalComparableVehicles(appraisalId)
      .then(response => {
        if (response.status < 300 && response.status >= 200) {
          return response.json()
        }
      })
      .then(res => {
        if (res && res.results) {
          setComparablesList(res.results)
        }
      })
  }
  const updateAppraisal = () => {
    APPRAISAL_ACTIONS.updateAppraisal(appraisalInfo.id, {
      dealer_retail_value: dealerRetailValueField.length ? parseInt(dealerRetailValueField) : 0,
      reconditioning_cost: reconditioningCostField.length ? parseInt(reconditioningCostField) : 0,
      certification_cost: certificationCostField.length ? parseInt(certificationCostField) : 0,
      body_work_cost: bodyWorkField.length ? parseInt(bodyWorkField) : 0,
      tire_cost: tiresField.length ? parseInt(tiresField) : 0,
      other_cost: otherField.length ? parseInt(otherField) : 0,
      gross_profit: targetedGrossProfitField.length ? parseInt(targetedGrossProfitField) : 0,
    }).then(response => {
      if (response.status < 300 && response.status >= 200) {
        toast('Successfully Updated Appraisal')
        setTradeAdjustmentEditingEnabled(false)
        retrieveAppraisal(appraisalInfo.id)
      }
    })
  }

  const retrieveAppraisal = appraisalId => {
    setLoadingAppraisal(true)
    APPRAISAL_ACTIONS.getAppraisal(appraisalId)
      .then(response => {
        if (response.status < 300 && response.status >= 200) {
          return response.json()
        } else {
          errorToast('There was an issue loading vehicle appraisal')
          setLoadingAppraisal(false)
        }
      })
      .then(res => {
        if (res && res.status && res.status === 'SUCCESS') {
          setAppraisalInfo(res)
          setLoadingAppraisal(false)
        } else {
          errorToast('There was an issue loading vehicle appraisal')
          setLoadingAppraisal(false)
        }
      })
  }

  if (appraisalInfo) {
    let {
      comparables,
      vehicle,
      dealer_value,
      ipacket_retail_value,
      location,
      sales_representative,
      store,
      is_editable,
      customer,
    } = appraisalInfo
    let costs =
      parseInt(reconditioningCostField.length ? reconditioningCostField : 0) +
      parseInt(certificationCostField.length ? certificationCostField : 0) +
      parseInt(bodyWorkField.length ? bodyWorkField : 0) +
      parseInt(tiresField.length ? tiresField : 0) +
      parseInt(targetedGrossProfitField.length ? targetedGrossProfitField : 0) +
      parseInt(otherField.length ? otherField : 0)
    let dealerTradeValue =
      parseInt(dealerRetailValueField.length ? dealerRetailValueField : 0) - costs
    if (dealerTradeValue < 0) {
      dealerTradeValue = 0
    }
    return (
      <div className="value-appraisal-container">
        <div className="value-appraisal">
          <div className="value-appraisal-section-header value-section">
            <div className="value-appraisal-section-header-button">Dashboard</div>
          </div>
          <div className="value-appraisal-general-info-container">
            <div className="value-appraisal-vehicle-info value-section">
              <div className="value-section-title">Vehicle Info</div>
              <div className="value-appraisal-vehicle-info-thumbnail">
                <IPacketOnlyFolderIcon />
              </div>
              <div className="value-appraisal-vehicle-info-breakdown">
                <h1>{`${vehicle.year} ${vehicle.make} ${vehicle.model}${
                  vehicle.trim ? ' ' + vehicle.trim : ''
                }`}</h1>
                <br />
                {vehicle.exterior_color ? (
                  <React.Fragment>
                    {`Exterior: ${vehicle.exterior_color}`}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {vehicle.interior_color ? (
                  <React.Fragment>
                    {`Interior: ${vehicle.interior_color}`}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {vehicle.vin ? (
                  <React.Fragment>
                    {`VIN: ${vehicle.vin}`}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {vehicle.mileage ? (
                  <React.Fragment>
                    {`Odometer: ${decimalFormatter.format(vehicle.mileage)} mi`}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {location ? (
                  <React.Fragment>
                    {`Location: ${location}`}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {customer ? (
                  <React.Fragment>
                    {`Customer: ${customer.first_name} ${customer.last_name}`}
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
            <div className="value-appraisal-analysis-info-container value-section">
              <div className="value-section-title">Retail Market Analysis</div>
              <div className="value-appraisal-analysis-info-graph-container">
                <div className="value-appraisal-analysis-info-graph"></div>
                <div className="value-appraisal-analysis-info-graph-overlay">
                  <div class="value-appraisal-analysis-info-graph-floating-values value-appraisal-analysis-info-graph-out-of-range-min-lower-bound">
                    {`$${decimalFormatter.format(
                      Math.round((ipacket_retail_value.low_price * 0.8) / 1000) * 1000,
                    )}`}
                  </div>
                  <div class="value-appraisal-analysis-info-graph-floating-values value-appraisal-analysis-info-graph-out-of-range-min">
                    {`$${decimalFormatter.format(
                      Math.round((ipacket_retail_value.low_price * 0.9) / 1000) * 1000,
                    )}`}
                  </div>
                  <div class="value-appraisal-analysis-info-graph-floating-values value-appraisal-analysis-info-graph-in-range-lower-bound">
                    {`$${decimalFormatter.format(ipacket_retail_value.low_price)}`}
                  </div>
                  <div class="value-appraisal-analysis-info-graph-floating-values value-appraisal-analysis-info-graph-in-range-center">
                    {`$${decimalFormatter.format(ipacket_retail_value.average_price)}`}
                  </div>
                  <div class="value-appraisal-analysis-info-graph-floating-values value-appraisal-analysis-info-graph-in-range-upper-bound">
                    {`$${decimalFormatter.format(ipacket_retail_value.high_price)}`}
                  </div>
                  <div class="value-appraisal-analysis-info-graph-floating-values value-appraisal-analysis-info-graph-out-of-range-max">
                    {`$${decimalFormatter.format(
                      Math.round((ipacket_retail_value.high_price * 1.1) / 1000) * 1000,
                    )}`}
                  </div>
                  <div class="value-appraisal-analysis-info-graph-floating-values value-appraisal-analysis-info-graph-out-of-range-max-upper-bound">
                    {`$${decimalFormatter.format(
                      Math.round((ipacket_retail_value.high_price * 1.2) / 1000) * 1000,
                    )}`}
                  </div>
                  <div className="value-appraisal-analysis-info-graph-arrow" />
                  <div className="value-appraisal-analysis-info-graph-market-price">
                    <div className="value-appraisal-analysis-info-graph-market-price-average">
                      {`$${decimalFormatter.format(ipacket_retail_value.average_price)}`}
                    </div>
                    <div className="value-appraisal-analysis-info-graph-market-price-sub-heading">
                      <h1>iPacket Avg Market Price</h1>
                      {`Based on ${comparables.count} Comp${comparables.count > 1 ? 's' : ''}`}
                      <br />
                      {`Original MSRP - $${decimalFormatter.format(vehicle.original_msrp)}`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="value-appraisal-analysis-info-history">
                <div className="value-appraisal-analysis-info-history-condition">
                  {vehicle.accident_history_label}
                </div>
                <div className="value-appraisal-analysis-info-history-sticker">
                  <Link to={`/stickerpull/${vehicle.vin}`} target="_blank">
                    View Sticker
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="value-appraisal-details-container value-section">
            <div className="value-section-title">Value Details</div>
            <div className="value-appraisal-details-display-general">
              <div className="value-appraisal-details-display-item">
                <div className="value-appraisal-details-display-item-label">
                  Original MSRP Value
                </div>
                <div className="value-appraisal-details-display-item-price">
                  {`$${decimalFormatter.format(vehicle.original_msrp)}`}
                </div>
              </div>
              <div className="value-appraisal-details-display-item">
                <div className="value-appraisal-details-display-item-label">
                  iPacket Average Retail Market Value
                </div>
                <div className="value-appraisal-details-display-item-price">
                  {`$${decimalFormatter.format(ipacket_retail_value.average_price)}`}
                </div>
                <div className="value-appraisal-details-display-item-sub-items">
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">
                      Average Percent of Original MSRP
                    </div>
                    <div className="value-appraisal-details-display-item-price">
                      {`${ipacket_retail_value.average_percentage_of_original_msrp}%`}
                    </div>
                  </div>
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">
                      iPacket Retail Market Range
                    </div>
                    <div className="value-appraisal-details-display-item-price">
                      {`$${decimalFormatter.format(
                        ipacket_retail_value.low_price,
                      )} - $${decimalFormatter.format(ipacket_retail_value.high_price)}`}
                    </div>
                  </div>
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">
                      Percent of Original MSRP Range
                    </div>
                    <div className="value-appraisal-details-display-item-price">
                      {`${ipacket_retail_value.low_percentage_of_original_msrp}% - ${ipacket_retail_value.high_percentage_of_original_msrp}%`}
                    </div>
                  </div>
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">Odometer Range</div>
                    <div className="value-appraisal-details-display-item-price">
                      {`${decimalFormatter.format(
                        comparables.mileage_min,
                      )} - ${decimalFormatter.format(comparables.mileage_max)} mi`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="value-appraisal-details-display-dealer">
              <div className="value-appraisal-details-display-dealer-buttons">
                {tradeAdjustmentEditingEnabled ? (
                  <React.Fragment>
                    <button
                      onClick={() => {
                        setTradeAdjustmentFields({
                          dealerRetailValueField: appraisalInfo.dealer_value.retail_value + '',
                          reconditioningCostField:
                            appraisalInfo.dealer_value.reconditioning_cost + '',
                          certificationCostField:
                            appraisalInfo.dealer_value.certification_cost + '',
                          bodyWorkField: appraisalInfo.dealer_value.body_work_cost + '',
                          tiresField: appraisalInfo.dealer_value.tire_cost + '',
                          otherField: appraisalInfo.dealer_value.other_cost + '',
                          targetedGrossProfitField: appraisalInfo.dealer_value.gross_profit + '',
                        })
                        setTradeAdjustmentEditingEnabled(false)
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="value-appraisal-details-display-dealer-button-save"
                      onClick={() => {
                        updateAppraisal()
                      }}
                    >
                      Save
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {is_editable ? (
                      <button
                        onClick={() => {
                          setTradeAdjustmentEditingEnabled(true)
                        }}
                      >
                        Edit
                      </button>
                    ) : (
                      <React.Fragment />
                    )}
                  </React.Fragment>
                )}
              </div>
              <div className="value-appraisal-details-display-item">
                <div className="value-appraisal-details-display-item-label">
                  Dealer Retail Value
                </div>
                <div className="value-appraisal-details-display-item-price">
                  {tradeAdjustmentEditingEnabled ? (
                    <input
                      value={dealerRetailValueField}
                      onChange={e => {
                        setDealerRetailValueField(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                  ) : (
                    <React.Fragment>{`$${decimalFormatter.format(
                      dealerRetailValueField.length ? dealerRetailValueField : '0',
                    )}`}</React.Fragment>
                  )}
                </div>
                <div className="value-appraisal-details-display-item-sub-items">
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">
                      Percent of Original MSRP
                    </div>
                    <div className="value-appraisal-details-display-item-price">
                      {`${Math.round((dealerRetailValueField / vehicle.original_msrp) * 100)}%`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="value-appraisal-details-display-item">
                <div className="value-appraisal-details-display-item-label">Costs</div>
                <div className="value-appraisal-details-display-item-price">
                  {`$${decimalFormatter.format(costs)}`}
                </div>
                <div className="value-appraisal-details-display-item-sub-items">
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">
                      Reconditioning Cost
                    </div>
                    <div className="value-appraisal-details-display-item-price">
                      {tradeAdjustmentEditingEnabled ? (
                        <input
                          value={reconditioningCostField}
                          onChange={e => {
                            setReconditioningCostField(e.target.value.replace(/\D/g, ''))
                          }}
                        />
                      ) : (
                        <React.Fragment>{`$${decimalFormatter.format(
                          reconditioningCostField.length ? reconditioningCostField : '0',
                        )}`}</React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">
                      Certification Cost
                    </div>
                    <div className="value-appraisal-details-display-item-price">
                      {tradeAdjustmentEditingEnabled ? (
                        <input
                          value={certificationCostField}
                          onChange={e => {
                            setCertificationCostField(e.target.value.replace(/\D/g, ''))
                          }}
                        />
                      ) : (
                        <React.Fragment>{`$${decimalFormatter.format(
                          certificationCostField.length ? certificationCostField : '0',
                        )}`}</React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">Body Work</div>
                    <div className="value-appraisal-details-display-item-price">
                      {tradeAdjustmentEditingEnabled ? (
                        <input
                          value={bodyWorkField}
                          onChange={e => {
                            setBodyWorkField(e.target.value.replace(/\D/g, ''))
                          }}
                        />
                      ) : (
                        <React.Fragment>{`$${decimalFormatter.format(
                          bodyWorkField.length ? bodyWorkField : '0',
                        )}`}</React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">Tires</div>
                    <div className="value-appraisal-details-display-item-price">
                      {tradeAdjustmentEditingEnabled ? (
                        <input
                          value={tiresField}
                          onChange={e => {
                            setTiresField(e.target.value.replace(/\D/g, ''))
                          }}
                        />
                      ) : (
                        <React.Fragment>{`$${decimalFormatter.format(
                          tiresField.length ? tiresField : '0',
                        )}`}</React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="value-appraisal-details-display-item">
                    <div className="value-appraisal-details-display-item-label">Other</div>
                    <div className="value-appraisal-details-display-item-price">
                      {tradeAdjustmentEditingEnabled ? (
                        <input
                          value={otherField}
                          onChange={e => {
                            setOtherField(e.target.value.replace(/\D/g, ''))
                          }}
                        />
                      ) : (
                        <React.Fragment>{`$${decimalFormatter.format(
                          otherField.length ? otherField : '0',
                        )}`}</React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="value-appraisal-details-display-item">
                <div className="value-appraisal-details-display-item-label">
                  Targeted Gross Profit
                </div>
                <div className="value-appraisal-details-display-item-price">
                  {tradeAdjustmentEditingEnabled ? (
                    <input
                      value={targetedGrossProfitField}
                      onChange={e => {
                        setTargetedGrossProfitField(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                  ) : (
                    <React.Fragment>{`$${decimalFormatter.format(
                      targetedGrossProfitField.length ? targetedGrossProfitField : '0',
                    )}`}</React.Fragment>
                  )}
                </div>
              </div>
              <div className="value-appraisal-details-display-item">
                <div className="value-appraisal-details-display-item-label">Dealer Trade Value</div>
                <div className="value-appraisal-details-display-item-price">
                  {`$${decimalFormatter.format(dealerTradeValue)}`}
                </div>
              </div>
            </div>
          </div>
          <div className="value-appraisal-comps-container value-section">
            <div className="value-section-title">Value Comps</div>
            {comparablesList ? (
              <div className="value-appraisal-comps-list">
                <div className="value-appraisal-comps-list-item value-appraisal-comps-list-header">
                  <div className="value-appraisal-comps-list-item-field">Vehicle</div>
                  <div className="value-appraisal-comps-list-item-field">Location</div>
                  <div className="value-appraisal-comps-list-item-field">Odometer</div>
                  <div className="value-appraisal-comps-list-item-field">Price Updated</div>
                  <div className="value-appraisal-comps-list-item-field">Retail Value</div>
                  <div className="value-appraisal-comps-list-item-field">MSRP</div>
                </div>
                {comparablesList.map(comp => {
                  const date = new Date(comp.price_updated_at)
                  const month = date.getUTCMonth() + 1
                  const day = date.getUTCDate()
                  const year = date
                    .getUTCFullYear()
                    .toString()
                    .slice(-2)
                  const now = new Date()
                  const msPerDay = 1000 * 60 * 60 * 24
                  const diffInDays = Math.abs(Math.round((date - now) / msPerDay))
                  const formattedDate = `${month}/${day}/${year} (${diffInDays} d)`
                  return (
                    <div className="value-appraisal-comps-list-item">
                      <div className="value-appraisal-comps-list-item-field">
                        {`${comp.year} ${comp.make} ${comp.model}${
                          comp.trim ? ' ' + comp.trim : ''
                        }`}
                        <br />
                        {`${comp.vin}`}
                      </div>
                      <div className="value-appraisal-comps-list-item-field">
                        {`${comp.location}`}
                      </div>
                      <div className="value-appraisal-comps-list-item-field">
                        {`${decimalFormatter.format(comp.mileage)} mi`}
                      </div>
                      <div className="value-appraisal-comps-list-item-field">
                        {`${formattedDate}`}
                      </div>
                      <div className="value-appraisal-comps-list-item-field value-appraisal-comps-list-item-field-retail-value">
                        <p>
                          <span>{`$${decimalFormatter.format(comp.price)}`}</span>{' '}
                          {` (${comp.price_percentage_of_original_msrp}%)`}
                        </p>
                      </div>
                      <div className="value-appraisal-comps-list-item-field">
                        {`$${decimalFormatter.format(comp.original_msrp)}`}
                        <Link to={`/stickerpull/${comp.vin}`} target="_blank">
                          View Sticker
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="value-appraisal-loading value-section">
      <LoadingSpinner loading />
    </div>
  )
}

const mapStatetoProps = state => ({
  appraisalInfo: state.value.appraisalInfo,
})
const mapDispatchToProps = {
  ...APPRAISAL_ACTIONS.dispatchActions,
}
export default connect(mapStatetoProps, mapDispatchToProps)(ValueAppraisal)
