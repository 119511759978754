import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { NavLink, Link } from 'react-router-dom'
import LoadingSpinner from '../../helpers/loadingspinner'
import Paginator from '../../helpers/paginator'

import CloseIcon from './icons/close-icon.js'
import './valueHistoricAppraisals.scss'
import APPRAISAL_ACTIONS from '../../../core/actions/appraisalActions.js'
const decimalFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' })
import debounce from 'lodash.debounce'

function ValueHistoricAppraisals(props) {
  const [loading, setLoading] = useState(true)
  const [searchField, setSearchField] = useState('')
  const [showFilterOptions, setShowFilterOptions] = useState(false)
  const [appraisalHistoryList, setAppraisalHistoryList] = useState(null)
  const [appraisalPageCount, setAppraisalPageCount] = useState(1)
  const [appraisalCurrentPage, setAppraisalCurrentPage] = useState(1)

  useEffect(() => {
    getAppraisalsList()
  }, [])
  useEffect(() => {
    debounceGetAppraisalsList(appraisalCurrentPage, searchField)
  }, [appraisalCurrentPage, searchField])

  const handleUpdateSearchField = val => {
    setSearchField(val)
  }
  const updateAppraisalCurrentPage = pageNum => {
    setAppraisalCurrentPage(pageNum)
  }
  const getAppraisalsList = (page = 1, search = '') => {
    setLoading(true)
    APPRAISAL_ACTIONS.listAppraisals(page, search)
      .then(response => {
        if (response.status < 300 && response.status >= 200) {
          return response.json()
        } else {
          setLoading(false)
        }
      })
      .then(res => {
        if (res && res.results) {
          setAppraisalHistoryList(res.results)
          setAppraisalPageCount(Math.ceil(res.count / res.page_size))
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
  }
  const debounceGetAppraisalsList = useMemo(
    () =>
      debounce((page, search) => {
        getAppraisalsList(page, search)
      }, 500),
    [],
  )
  return (
    <div className="value-historic-appraisals-container">
      <div className="value-historic-appraisals value-section">
        {loading ? (
          <div className="value-historic-appraisals-loading">
            <LoadingSpinner loading />
          </div>
        ) : (
          <React.Fragment />
        )}
        <div className="value-section-title">Appraisal History</div>
        <div className="value-historic-appraisals-controls-top">
          <div className="value-historic-appraisal-controls-top-upper">
            <div className="value-historic-appraisals-controls-top-layer-search-filter">
              <div className="value-historic-appraisals-controls-top-input">
                <input
                  value={searchField}
                  placeholder={'Search'}
                  onChange={e => {
                    handleUpdateSearchField(e.target.value)
                  }}
                />
                <div
                  className="value-historic-appraisals-controls-top-input-clear"
                  onClick={() => {
                    handleUpdateSearchField('')
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
              <button
                onClick={() => {
                  setShowFilterOptions(!showFilterOptions)
                }}
              >
                Filter
              </button>
            </div>
            <div className="value-historic-appraisals-controls-top-layer-paginator">
              <Paginator
                page={appraisalCurrentPage}
                updatePage={page => {
                  updateAppraisalCurrentPage(page)
                }}
                numPages={appraisalPageCount}
              />
            </div>
          </div>
          <div className="value-historic-appraisal-controls-top-lower">
            <div className="value-historic-appraisal-controls-top-filters">
              <div className="value-historic-appraisal-controls-top-filters-row">
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">Make</div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <select>
                        <option>All</option>
                      </select>
                    </div>
                  </div>

                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">Model</div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <select>
                        <option>All</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Min Year
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <select>
                        <option>All</option>
                      </select>
                    </div>
                  </div>

                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Max Year
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <select>
                        <option>All</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="value-historic-appraisal-controls-top-filters-row">
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Min Mileage
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <input />
                    </div>
                  </div>

                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Max Mileage
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <input />
                    </div>
                  </div>
                </div>
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Min MSRP
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <input />
                    </div>
                  </div>

                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Max MSRP
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <input />
                    </div>
                  </div>
                </div>
              </div>

              <div className="value-historic-appraisal-controls-top-filters-row">
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Min Retail Value
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <input />
                    </div>
                  </div>

                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Max Retail Value
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <input />
                    </div>
                  </div>
                </div>
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filters-row-group">
                    <div className="value-historic-appraisal-controls-top-filter">
                      <div className="value-historic-appraisal-controls-top-filter-label">
                        Date Range
                      </div>
                      <div className="value-historic-appraisal-controls-top-filter-field">
                        <select>
                          <option>All</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="value-historic-appraisal-controls-top-filters-row">
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">
                      Sales Rep
                    </div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <select>
                        <option>All</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="value-historic-appraisal-controls-top-filters-row-group">
                  <div className="value-historic-appraisal-controls-top-filter">
                    <div className="value-historic-appraisal-controls-top-filter-label">Status</div>
                    <div className="value-historic-appraisal-controls-top-filter-field">
                      <select>
                        <option>All</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="value-historic-appraisals-table">
          <div className="value-historic-appraisals-table-item value-historic-appraisals-table-header">
            <div className="value-historic-appraisals-table-item-field">Date</div>
            <div className="value-historic-appraisals-table-item-field">Vehicle</div>
            <div className="value-historic-appraisals-table-item-field">Location</div>
            <div className="value-historic-appraisals-table-item-field">Odometer</div>
            <div className="value-historic-appraisals-table-item-field ">Retail Value</div>
            <div className="value-historic-appraisals-table-item-field">MSRP</div>
            <div className="value-historic-appraisals-table-item-field">View</div>
          </div>
          {appraisalHistoryList ? (
            <React.Fragment>
              {appraisalHistoryList.map(appraisal => {
                const date = new Date(appraisal.created_at)
                const month = date.getUTCMonth() + 1
                const day = date.getUTCDate()
                const year = date
                  .getUTCFullYear()
                  .toString()
                  .slice(-2)
                const formattedCreatedDate = `${month}/${day}/${year}`

                return (
                  <div className="value-historic-appraisals-table-item">
                    <div className="value-historic-appraisals-table-item-field">
                      {`${formattedCreatedDate}`}
                    </div>
                    <div className="value-historic-appraisals-table-item-field">
                      {appraisal.ipacket_retail_value.average_price ? (
                        <React.Fragment>
                          {`${appraisal.vehicle.year} ${appraisal.vehicle.make} ${
                            appraisal.vehicle.model
                          }${appraisal.vehicle.trim ? ' ' + appraisal.vehicle.trim : ''}`}
                          <br />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span>Failed to Appraise</span>
                        </React.Fragment>
                      )}
                      {`${appraisal.vehicle.vin}`}
                    </div>
                    <div className="value-historic-appraisals-table-item-field">
                      {`${appraisal.location}`}
                    </div>
                    <div className="value-historic-appraisals-table-item-field">
                      {`${decimalFormatter.format(appraisal.vehicle.mileage)} mi`}
                    </div>
                    <div className="value-historic-appraisals-table-item-field value-historic-appraisals-table-item-field-retail-value">
                      {appraisal.ipacket_retail_value.average_price ? (
                        <React.Fragment>
                          <p>
                            <span>{`$${decimalFormatter.format(
                              appraisal.ipacket_retail_value.average_price,
                            )}`}</span>
                            {` - ${appraisal.ipacket_retail_value.average_percentage_of_original_msrp}%`}
                          </p>
                        </React.Fragment>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="value-historic-appraisals-table-item-field">
                      {appraisal.vehicle.original_msrp ? (
                        <React.Fragment>
                          {`$${decimalFormatter.format(appraisal.vehicle.original_msrp)}`}
                          <Link to={`/stickerpull/${appraisal.vehicle.vin}`} target="_blank">
                            View Sticker
                          </Link>
                        </React.Fragment>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="value-historic-appraisals-table-item-field">
                      {appraisal.ipacket_retail_value.average_price ? (
                        <NavLink to={`/value/appraisal/${appraisal.id}`}>View</NavLink>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                )
              })}
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div className="value-historic-appraisals-controls-bottom"></div>
      </div>
    </div>
  )
}
export default withRouter(ValueHistoricAppraisals)
