import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import './valueSettings.scss'
import LoadingSpinner from '../../helpers/loadingspinner'

import APPRAISAL_ACTIONS from '../../../core/actions/appraisalActions.js'
import PROFILE_ACTIONS from '../../../core/actions/users/profileactions'

function ValueSettings(props) {
  const { authentication } = props

  const [loading, setLoading] = useState(false)
  const [userStores, setUserStores] = useState(null)
  const [selectedStoreId, setSelectedStoreId] = useState(authentication.userInfo.default_store_id)
  const [template, setTemplate] = useState(null)
  const [reconditioningCostField, setReconditioningCostField] = useState('')
  const [certificationCostField, setCertificationCostField] = useState('')
  const [bodyWorkCostField, setBodyWorkCostField] = useState('')
  const [tireCostField, setTireCostField] = useState('')
  const [grossProfitField, setGrossProfitField] = useState('')
  const [otherCostField, setOtherCostField] = useState('')

  useEffect(() => {
    setLoading(true)
    PROFILE_ACTIONS.getUserStoreGroups(authentication.userInfo.user_id, null).then(res => {
      if (res) {
        setUserStores(res)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    getTemplate()
  }, [selectedStoreId])

  const errorToast = message => {
    toast(message, { className: 'appraisal-display-error-toast', autoClose: false })
  }

  const handleUpdateTemplate = newTemplate => {
    setReconditioningCostField(newTemplate.reconditioning_cost + '')
    setCertificationCostField(newTemplate.certification_cost + '')
    setBodyWorkCostField(newTemplate.body_work_cost + '')
    setTireCostField(newTemplate.tire_cost + '')
    setGrossProfitField(newTemplate.gross_profit + '')
    setOtherCostField(newTemplate.other_cost + '')
    setTemplate(newTemplate)
  }
  const getTemplate = () => {
    setLoading(true)
    APPRAISAL_ACTIONS.getStoreTradeTemplate(selectedStoreId)
      .then(response => {
        if (response.status < 300 && response.status >= 200) {
          return response.json()
        } else if (response.status === 404) {
          return response.json()
        } else {
          setLoading(false)
          errorToast('There was an issue saving store settings')
        }
      })
      .then(res => {
        if (res) {
          if (res.error_code && res.error_code === 'not_found') {
            handleUpdateTemplate({
              existingTemplate: false,
              reconditioning_cost: 0,
              certification_cost: 0,
              body_work_cost: 0,
              tire_cost: 0,
              gross_profit: 0,
              other_cost: 0,
            })
            setLoading(false)
          } else {
            handleUpdateTemplate({
              existingTemplate: true,
              ...res,
            })
            setLoading(false)
          }
        }
      })
  }
  const generateStoreOptions = () => {
    let optionElements = []
    for (let i = 0; i < userStores.length; i++) {
      optionElements.push(
        <option value={userStores[i].company_store_id}>{`${userStores[i].company_store_name} (${
          userStores[i].store_type === 1 ? 'New' : 'Pre-Owned'
        })`}</option>,
      )
    }
    return optionElements
  }

  const handleSaveTemplate = () => {
    setLoading(true)
    let newTemplate = {
      reconditioning_cost: reconditioningCostField.length ? parseInt(reconditioningCostField) : 0,
      certification_cost: certificationCostField.length ? parseInt(certificationCostField) : 0,
      body_work_cost: bodyWorkCostField.length ? parseInt(bodyWorkCostField) : 0,
      gross_profit: grossProfitField.length ? parseInt(grossProfitField) : 0,
      other_cost: otherCostField.length ? parseInt(otherCostField) : 0,
    }
    if (template.existingTemplate) {
      APPRAISAL_ACTIONS.updateStoreTradeTemplate(selectedStoreId, newTemplate).then(response => {
        if (response.status < 300 && response.status >= 200) {
          getTemplate()
          toast('Template Updated')
        } else {
          setLoading(false)
          errorToast('There was an issue saving store settings')
        }
      })
    } else {
      APPRAISAL_ACTIONS.createStoreTradeTemplate(selectedStoreId, newTemplate).then(response => {
        if (response.status < 300 && response.status >= 200) {
          getTemplate()
          toast('Template Created')
        } else {
          setLoading(false)
          errorToast('There was an issue saving store settings')
        }
      })
    }
  }
  const handleResetForm = () => {
    handleUpdateTemplate({ ...template })
  }

  return (
    <div className="value-settings-container value-section">
      <div className="value-settings">
        {loading ? (
          <div className="value-settings-loading">
            <LoadingSpinner loading />
          </div>
        ) : (
          <React.Fragment />
        )}

        <div className="value-settings-title">Settings</div>
        <hr />
        <div className="value-settings-store-selector">
          <select
            value={selectedStoreId}
            onChange={e => {
              setSelectedStoreId(e.target.value)
            }}
          >
            {userStores ? generateStoreOptions() : <React.Fragment />}
          </select>
        </div>

        <div className="value-settings-form-container">
          <div className="value-settings-form-title">Default Appraisal Values</div>
          {template ? (
            <div className="value-setting-form">
              <div className="value-settings-form-row">
                <div className="value-settings-form-field">
                  <div className="value-settings-form-field-label">Reconditioning Cost</div>
                  <div className="value-settings-form-field-input">
                    <input
                      value={reconditioningCostField}
                      onChange={e => {
                        setReconditioningCostField(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                  </div>
                </div>

                <div className="value-settings-form-field">
                  <div className="value-settings-form-field-label">Certification Cost</div>
                  <div className="value-settings-form-field-input">
                    <input
                      value={certificationCostField}
                      onChange={e => {
                        setCertificationCostField(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                  </div>
                </div>

                <div className="value-settings-form-field">
                  <div className="value-settings-form-field-label">Body Work</div>
                  <div className="value-settings-form-field-input">
                    <input
                      value={bodyWorkCostField}
                      onChange={e => {
                        setBodyWorkCostField(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                  </div>
                </div>

                <div className="value-settings-form-field">
                  <div className="value-settings-form-field-label">Tires</div>
                  <div className="value-settings-form-field-input">
                    <input
                      value={tireCostField}
                      onChange={e => {
                        setTireCostField(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                  </div>
                </div>

                <div className="value-settings-form-field">
                  <div className="value-settings-form-field-label">Gross Profit</div>
                  <div className="value-settings-form-field-input">
                    <input
                      value={grossProfitField}
                      onChange={e => {
                        setGrossProfitField(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="value-settings-form-buttons">
                <button
                  className="value-settings-form-button value-settings-form-button-clear"
                  onClick={() => {
                    handleResetForm()
                  }}
                >
                  Cancel
                </button>
                <button
                  className="value-settings-form-button value-settings-form-button-submit"
                  onClick={() => {
                    handleSaveTemplate()
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    </div>
  )
}
const mapStatetoProps = state => ({
  authentication: state.authentication,
})
const mapDispatchToProps = {
  ...APPRAISAL_ACTIONS.dispatchActions,
}
export default connect(mapStatetoProps, mapDispatchToProps)(ValueSettings)
