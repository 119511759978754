import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './valueNewAppraisal.scss'
import APPRAISAL_ACTIONS from '../../../core/actions/appraisalActions.js'
import PROFILE_ACTIONS from '../../../core/actions/users/profileactions'
import CloseIcon from './icons/close-icon.js'
import LoadingSpinner from '../../helpers/loadingspinner'

import { toast } from 'react-toastify'

function ValueNewAppraisal({
  history,
  authentication,
  formFields,
  updateFormFields,
  clearFormFields,
  setAppraisalInfo,
}) {
  const {
    showCustomerInfoSection,
    vinField,
    mileageField,
    zipCodeField,
    oemCertifiedField,
    vehicleHistoryField,
    dealershipField,
    salesRepField,
    customerField,
  } = formFields

  const [userStores, setUserStores] = useState(null)
  const [saleReps, setSalesReps] = useState(null)
  const [salesRepSearchField, setSalesRepSearchField] = useState('')
  const [salesRepSearchResults, setSalesRepSearchResults] = useState(null)
  const [showSalesRepSearchResults, setShowSalesRepSearchResults] = useState(false)

  const [customer, setCustomers] = useState(null)
  const [customerSearchField, setCustomerSearchField] = useState('')
  const [customerSearchResults, setCustomerSearchResults] = useState(null)
  const [showCustomerSearchResults, setShowCustomerSearchResults] = useState(false)

  const [loadingAppraisal, setLoadingAppraisal] = useState(false)

  const [vehicleHistoryOptions, setVehicleHistoryOptions] = useState(null)

  useEffect(() => {
    dealershipFieldSetup()
    salesRepFieldSetup()
    vehicleHistoryFieldSetup()
    customerFieldSetup()
  }, [])

  useEffect(() => {
    if (salesRepSearchField.length) {
      APPRAISAL_ACTIONS.getTeamMembers(dealershipField, salesRepSearchField, 1, 20)
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res && res.results) {
            setSalesRepSearchResults(res.results)
          }
        })
    } else {
      setSalesRepSearchResults(null)
    }
  }, [salesRepSearchField])

  useEffect(() => {
    if (customerSearchField.length) {
      APPRAISAL_ACTIONS.getCustomers(dealershipField, customerSearchField, 1, 20)
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res && res.results) {
            setCustomerSearchResults(res.results)
          }
        })
    } else {
      setCustomerSearchResults(null)
    }
  }, [customerSearchField])

  const errorToast = message => {
    toast(message, { className: 'appraisal-display-error-toast', autoClose: false })
  }

  const allowSubmit =
    vinField.length === 17 && zipCodeField.length === 5 && !!dealershipField && !!salesRepField

  const submitAppraisal = () => {
    setLoadingAppraisal(true)
    APPRAISAL_ACTIONS.createAppraisal({
      accident_history_id: parseInt(vehicleHistoryField),
      collateral_value: null,
      customer_id:
        customerField && customerField.customer_id ? parseInt(customerField.customer_id) : null,
      is_oem_certified: oemCertifiedField,
      mileage: mileageField && mileageField.length ? parseInt(mileageField) : 0,
      sales_representative_id: parseInt(salesRepField.user_id),
      store_id: parseInt(dealershipField),
      vin: vinField,
      zip_code: zipCodeField,
    })
      .then(response => {
        if (response.status < 300 && response.status >= 200) {
          return response.json()
        } else {
          setLoadingAppraisal(false)
          errorToast('There was an issue loading vehicle appraisal')
        }
      })
      .then(res => {
        if (res && res.status) {
          if (res.status === 'PENDING' || res.status === 'STARTED') {
            setupPollingAppraisal(res.id)
          } else {
            errorToast('There was an issue loading vehicle appraisal')
            setLoadingAppraisal(false)
          }
        } else {
          errorToast('There was an issue loading vehicle appraisal')
          setLoadingAppraisal(false)
        }
      })
  }

  let pollingAppraisal = null
  let pollFreq = 1000
  const pollLimit = Math.abs((1000 * 60 * 15) / pollFreq) //15 minutes
  const setupPollingAppraisal = (appraisalId, pollCount = 0) => {
    pollingAppraisal = setTimeout(() => {
      APPRAISAL_ACTIONS.getAppraisalStatus(appraisalId)
        .then(response => {
          if (response.status < 300 && response.status >= 200) {
            try {
              let retryAfter = parseInt(response.headers.get('Retry-After'))
              retryAfter = retryAfter * 100 //convert seconds to ms
              if (retryAfter >= 1000) {
                pollFreq = retryAfter
              } else {
                console.log('Retry-After Set below minimum limit of 500ms')
              }
            } catch (e) {
              console.log('Issue Using Retry-After Header')
            }
            return response.json()
          } else {
            setLoadingAppraisal(false)
            errorToast('There was an issue loading vehicle appraisal')
          }
        })
        .then(res => {
          if (res && res.status && res.id) {
            if (res.status === 'SUCCESS') {
              clearFormFields()
              history.push(`/value/appraisal/${res.id}`)
            } else if (res.status === 'PENDING') {
              if (pollCount < pollLimit) {
                setupPollingAppraisal(appraisalId, pollCount + 1)
              } else {
                setLoadingAppraisal(false)
                errorToast('There was an issue loading vehicle appraisal')
              }
            } else if (res.status === 'FAILURE') {
              setLoadingAppraisal(false)
              if (res.detail) {
                errorToast(res.detail)
              } else {
                errorToast('There was an issue loading vehicle appraisal')
              }
            } else {
              setLoadingAppraisal(false)
              errorToast('There was an issue loading vehicle appraisal')
            }
          }
        })
    }, pollFreq)
  }

  const dealershipFieldSetup = setDefault => {
    if (setDefault || !dealershipField) {
      handleFieldChange('dealershipField', authentication.userInfo.default_store_id)
    }
    PROFILE_ACTIONS.getUserStoreGroups(authentication.userInfo.user_id, null).then(res => {
      if (res) {
        setUserStores(res)
      }
    })
  }

  const salesRepFieldSetup = setDefault => {
    if (setDefault || !salesRepField) {
      handleFieldChange('salesRepField', {
        full_name: `${authentication.userInfo.first_name} ${authentication.userInfo.last_name}`,
        user_id: authentication.user.user.profile.auth_user_id,
      })
    }
  }

  const customerFieldSetup = setDefault => {
    if (setDefault || !customerField) {
      handleFieldChange('customerField', null)
    }
  }

  const vehicleHistoryFieldSetup = setDefault => {
    if (setDefault || !vehicleHistoryField) {
      handleFieldChange('vehicleHistoryField', 1)
    }
    APPRAISAL_ACTIONS.getAccidentHistories()
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res) {
          setVehicleHistoryOptions(res)
        }
      })
  }

  const stripNonAlphanumeric = val => {
    return val.replace(/[^a-zA-Z0-9]/g, '')
  }
  const stripNonNumeric = val => {
    console.log(val)
    return val.replace(/[^0-9]/g, '')
  }

  let blurTimeout
  const handleFocusSalesRep = () => {
    clearTimeout(blurTimeout)
    setShowSalesRepSearchResults(true)
  }
  const handleBlurSaleRep = () => {
    blurTimeout = setTimeout(() => {
      setShowSalesRepSearchResults(false)
    }, 200)
  }

  const handleFocusCustomer = () => {
    clearTimeout(blurTimeout)
    setShowCustomerSearchResults(true)
  }
  const handleBlurCustomer = () => {
    blurTimeout = setTimeout(() => {
      setShowCustomerSearchResults(false)
    }, 200)
  }

  const handleClearForm = () => {
    clearFormFields()
    dealershipFieldSetup(true)
    salesRepFieldSetup(true)
  }

  const handleFieldChange = (name, value) => {
    updateFormFields({
      [name]: value,
    })
  }
  const handleVinFieldChange = (name, value) => {
    if (value.length <= 17) {
      handleFieldChange(name, value)
    }
  }

  const handleMileageFieldChange = (name, value) => {
    let newValue = stripNonNumeric(value)
    if (newValue.length <= 7) {
      handleFieldChange(name, newValue)
    }
  }

  const handleZipCodeFieldChange = (name, value) => {
    let newValue = stripNonNumeric(value)
    if (newValue.length <= 5) {
      handleFieldChange(name, newValue)
    }
  }

  const handleSalesRepSearchFieldChange = value => {
    setSalesRepSearchField(value)
  }

  const handleCustomerSearchFieldChange = value => {
    setCustomerSearchField(value)
  }

  const generateDealershipOptions = () => {
    let optionElements = []
    for (let i = 0; i < userStores.length; i++) {
      optionElements.push(
        <option value={userStores[i].company_store_id}>{`${userStores[i].company_store_name} (${
          userStores[i].store_type === 1 ? 'New' : 'Pre-Owned'
        })`}</option>,
      )
    }
    return optionElements
  }

  const generateVehicleHistoryOptions = () => {
    let optionElements = []
    for (let i = 0; i < vehicleHistoryOptions.length; i++) {
      optionElements.push(
        <option value={vehicleHistoryOptions[i].id}>{`${vehicleHistoryOptions[i].label}`}</option>,
      )
    }
    return optionElements
  }

  return (
    <div className="value-new-appraisal-container value-section">
      <div className="value-new-appraisal-title">
        <h1>Vehicle Appraisal</h1>
        <h2>Enter vehicle information to generate an accurate, up-to-date appraisal.</h2>
      </div>
      <div className="value-new-appraisal-form-container">
        <div className="value-new-appraisal-form-section">
          <div className="value-new-appraisal-form-section-row">
            <div className="value-new-appraisal-form-field">
              <div className="value-new-appraisal-form-field-title">
                VIN
                {vinField.length < 17 ? (
                  <div className="value-new-appraisal-form-field-title-required" />
                ) : (
                  <React.Fragment />
                )}
              </div>
              <div className="value-new-appraisal-form-field-input">
                <input
                  type="text"
                  value={vinField}
                  name="vinField"
                  placeholder="VIN"
                  onChange={e => {
                    handleVinFieldChange(e.target.name, e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="value-new-appraisal-form-section-row">
            <div className="value-new-appraisal-form-field">
              <div className="value-new-appraisal-form-field-title">
                Mileage
                <div className="value-new-appraisal-form-field-title-postfix">
                  {`- ${/^0*$/.test(mileageField) ? 'New' : 'Used'}`}
                </div>
              </div>
              <div className="value-new-appraisal-form-field-input">
                <input
                  type="text"
                  value={mileageField}
                  name="mileageField"
                  placeholder="Mileage"
                  onChange={e => {
                    handleMileageFieldChange(e.target.name, e.target.value)
                  }}
                />
                <div className="value-new-appraisal-form-field-input-unit">mi</div>
              </div>
            </div>
            <div className="value-new-appraisal-form-field">
              <div className="value-new-appraisal-form-field-title">
                ZIP Code
                {zipCodeField.length < 5 ? (
                  <div className="value-new-appraisal-form-field-title-required" />
                ) : (
                  <React.Fragment />
                )}
              </div>
              <div className="value-new-appraisal-form-field-input">
                <input
                  type="text"
                  value={zipCodeField}
                  name="zipCodeField"
                  placeholder="Zip Code"
                  onChange={e => {
                    handleZipCodeFieldChange(e.target.name, e.target.value)
                  }}
                />
              </div>
            </div>
          </div>

          <div className="value-new-appraisal-form-section-row">
            <div className="value-new-appraisal-form-field">
              <div className="value-new-appraisal-form-field-title">OEM Certified</div>
              <div className="value-new-appraisal-form-field-input">
                <select
                  value={oemCertifiedField}
                  name="oemCertifiedField"
                  onChange={e => {
                    handleFieldChange(e.target.name, JSON.parse(e.target.value))
                  }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div className="value-new-appraisal-form-field">
              <div className="value-new-appraisal-form-field-title">Vehicle History</div>
              <div className="value-new-appraisal-form-field-input">
                <select
                  value={vehicleHistoryField}
                  name="vehicleHistoryField"
                  onChange={e => {
                    handleFieldChange(e.target.name, JSON.parse(e.target.value))
                  }}
                >
                  {vehicleHistoryOptions ? generateVehicleHistoryOptions() : <React.Fragment />}
                </select>
              </div>
            </div>
          </div>

          <div className="value-new-appraisal-form-section-row">
            <div className="value-new-appraisal-form-field">
              <div className="value-new-appraisal-form-field-title">Dealership</div>
              <div className="value-new-appraisal-form-field-input">
                <select
                  value={dealershipField}
                  name="dealershipField"
                  onChange={e => {
                    handleFieldChange(e.target.name, e.target.value)
                  }}
                >
                  {userStores ? generateDealershipOptions() : null}
                </select>
              </div>
            </div>
            <div className="value-new-appraisal-form-field">
              <div className="value-new-appraisal-form-field-title">Sales Rep</div>
              <div className="value-new-appraisal-form-field-input">
                {salesRepField ? (
                  <div className="value-new-appraisal-form-field-input-show-item">
                    <div className="value-new-appraisal-form-field-input-show-item-label-container">
                      <div className="value-new-appraisal-form-field-input-show-item-label">{`${salesRepField.full_name}`}</div>
                    </div>
                    {authentication.userInfo.is_admin ? (
                      <div
                        className="value-new-appraisal-form-field-input-show-item-clear"
                        onClick={e => {
                          handleFieldChange('salesRepField', null)
                        }}
                      >
                        <CloseIcon />
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                ) : (
                  <div className="value-new-appraisal-form-field-input-search">
                    <input
                      type="text"
                      value={salesRepSearchField}
                      placeholder="Search Team Member"
                      onFocus={handleFocusSalesRep}
                      onBlur={handleBlurSaleRep}
                      onChange={e => {
                        setSalesRepSearchField(e.target.value)
                      }}
                    />
                    <div
                      className={`value-new-appraisal-form-field-input-search-results ${
                        showSalesRepSearchResults
                          ? ''
                          : 'value-new-appraisal-form-field-input-search-results-hide'
                      }`}
                    >
                      {salesRepSearchResults ? (
                        salesRepSearchResults.map(rep => {
                          return (
                            <div
                              className="value-new-appraisal-form-field-input-search-results-item"
                              onClick={() => {
                                handleFieldChange('salesRepField', {
                                  full_name: `${rep.first_name} ${rep.last_name}`,
                                  user_id: rep.auth_user_id,
                                })
                                handleSalesRepSearchFieldChange('')
                              }}
                            >
                              {`${rep.first_name} ${rep.last_name}`}
                              <br />
                              {rep.username}
                            </div>
                          )
                        })
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="value-new-appraisal-form-section-toggle">
          <div className="value-new-appraisal-form-section-toggle-hr">
            <hr />
          </div>
          <div
            className="value-new-appraisal-form-section-toggle-button"
            onClick={() => {
              handleFieldChange('showCustomerInfoSection', !showCustomerInfoSection)
            }}
          >
            {showCustomerInfoSection ? '-' : '+'}
          </div>
          <div className="value-new-appraisal-form-section-toggle-hr">
            <hr />
          </div>
        </div>
        {showCustomerInfoSection ? (
          <div className="value-new-appraisal-form-section">
            <div className="value-new-appraisal-form-section-title">Customer</div>
            <div className="value-new-appraisal-form-section-row">
              <div className="value-new-appraisal-form-field">
                <div className="value-new-appraisal-form-field-input">
                  {customerField ? (
                    <div className="value-new-appraisal-form-field-input-show-item">
                      <div className="value-new-appraisal-form-field-input-show-item-label-container">
                        <div className="value-new-appraisal-form-field-input-show-item-label">{`${customerField.full_name} | ${customerField.email}`}</div>
                      </div>
                      <div
                        className="value-new-appraisal-form-field-input-show-item-clear"
                        onClick={e => {
                          handleFieldChange('customerField', null)
                        }}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="value-new-appraisal-form-field-input-search">
                        <input
                          type="text"
                          value={customerSearchField}
                          placeholder="Search Customer"
                          onFocus={handleFocusCustomer}
                          onBlur={handleBlurCustomer}
                          onChange={e => {
                            setCustomerSearchField(e.target.value)
                          }}
                        />
                        <div
                          className={`value-new-appraisal-form-field-input-search-results ${
                            showCustomerSearchResults
                              ? ''
                              : 'value-new-appraisal-form-field-input-search-results-hide'
                          }`}
                        >
                          {customerSearchResults ? (
                            customerSearchResults.map(customer => {
                              return (
                                <div
                                  className="value-new-appraisal-form-field-input-search-results-item"
                                  onClick={() => {
                                    handleFieldChange('customerField', {
                                      full_name: `${customer.first_name} ${customer.last_name}`,
                                      ...customer,
                                    })
                                    handleCustomerSearchFieldChange('')
                                  }}
                                >
                                  {`${customer.first_name} ${customer.last_name}`}
                                  <br />
                                  {customer.email}
                                  <br />
                                  {customer.mobile_number}
                                </div>
                              )
                            })
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                      <button className="value-new-appraisal-form-field-input-search-button">
                        Add New Contact +
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
        <div className="value-new-appraisal-form-buttons">
          <button
            className="value-new-appraisal-form-button value-new-appraisal-form-button-clear"
            onClick={() => {
              handleClearForm()
            }}
          >
            CLEAR FORM
          </button>
          <button
            disabled={!allowSubmit}
            className="value-new-appraisal-form-button value-new-appraisal-form-button-submit"
            onClick={() => {
              submitAppraisal()
            }}
          >
            SUBMIT
          </button>
        </div>
      </div>
      {loadingAppraisal ? (
        <div className="value-new-appraisal-loading">
          <LoadingSpinner loading />
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  )
}
const mapStatetoProps = state => ({
  formFields: state.value.newAppraisalFormFields,
  authentication: state.authentication,
})
const mapDispatchToProps = {
  ...APPRAISAL_ACTIONS.dispatchActions,
}
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(ValueNewAppraisal))
