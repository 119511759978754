import VALUE_TYPES from '../_constants/valueTypes.js'

const initialState = {
  appraisalInfo: null,
  newAppraisalFormFields: {
    showCustomerInfoSection: false,
    vinField: '',
    zipCodeField: '',
    mileageField: '0',
    oemCertifiedField: false,
    vehicleHistoryField: '1',
    dealershipField: '',
    salesRepField: null,
    customerField: null,
  },
}

export function value(state, action) {
  if (!state) {
    state = { ...initialState }
  }
  switch (action.type) {
    case VALUE_TYPES.VALUE_SET_NEW_APPRAISAL_FIELDS:
      return {
        ...state,
        newAppraisalFormFields: {
          ...state.newAppraisalFormFields,
          ...action.fields,
        },
      }
      break
    case VALUE_TYPES.VALUE_CLEAR_NEW_APPRAISAL_FIELDS:
      return {
        ...state,
        newAppraisalFormFields: {
          ...initialState.newAppraisalFormFields,
        },
      }
      break
    case VALUE_TYPES.VALUE_SET_APPRAISAL_INFO:
      return {
        ...state,
        appraisalInfo: { ...action.appraisalInfo },
      }
    default:
      return state
  }
}
