import './appraisalDocument.scss'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import APPRAISAL_ACTIONS from '../../../../core/actions/appraisalActionsOld.js'

import { toast } from 'react-toastify'
const decimalFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' })
import CloseIcon from './closeicon.js'
import CopyIcon from './copy-icon.js'
import PersonIcon from './person-icon.js'
import WarningIcon from './warning-icon.js'
import CarCrashIcon from './car-crash-icon.js'
import CarClearIcon from './car-clear-icon.js'
import IPacketRingLogo from '../../../../core/assets/images/icons/ipacketringlogo.js'
import EditIcon from '../../../../core/assets/images/icons/editicon'

export default function AppraisalDocument({ appraisalInfo, appraisalComparables, debug }) {
  const { vehicle_details } = appraisalInfo

  const [showFeedbackForm, setShowFeedbackForm] = useState(false)

  const [marketValueField, setMarketValueField] = useState(
    vehicle_details.current_retail_value ? vehicle_details.current_retail_value : '',
  )
  const [tradeAdjustmentFields, setTradeAdjustmentFields] = useState(null)
  // const [reconCostField, setReconCostField] = useState('')
  // const [bodyWorkCostField, setBodyWorkCostField] = useState('')
  // const [tireCostField, setTireCostField] = useState('')
  // const [certificationCostField, setCertificationCostField] = useState('')
  // const [profitField, setProfitField] = useState('')
  const [tradeInValueField, setTradeInValueField] = useState(marketValueField)

  const [tradeInValue, setTradeInValue] = useState(null)

  useEffect(() => {
    APPRAISAL_ACTIONS.getAvailableVehicleAppraisalTradeAdjustmentFields()
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res && res.results) {
          let fields = [...res.results]
          for (let i = 0; i < fields.length; i++) {
            fields[i].value = ''
          }
          setTradeAdjustmentFields(fields)
        }
      })
  }, [])

  useEffect(() => {
    if (tradeAdjustmentFields) {
      updateTradeInValue()
    }
  }, [marketValueField, tradeAdjustmentFields])

  const updateTradeAdjustmentField = (index, value) => {
    let updatedTradeAdjustmentFields = [...tradeAdjustmentFields]
    updatedTradeAdjustmentFields[index].value = value
    setTradeAdjustmentFields(updatedTradeAdjustmentFields)
  }

  const updateTradeInValue = () => {
    let total = parseInt(marketValueField)
    total = isNaN(total) ? 0 : total
    //Weak Typing

    tradeAdjustmentFields.forEach(field => {
      let val = parseInt(field.value)
      val = isNaN(val) ? 0 : val

      if (field.type === 'subtraction') {
        total -= val
      } else if (field.type === 'addition') {
        total += val
      }
    })
    setTradeInValueField(total < 0 ? 0 : total)
  }

  const submitTradeAdjustmentFields = () => {
    //Rebuilding Object to Endpoint Spec
    let adjustmentFields = []
    tradeAdjustmentFields.forEach(field => {
      let fieldVal = parseInt(field.value)
      if (!isNaN(fieldVal) && fieldVal > 0) {
        adjustmentFields.push({ id: field.id, value: fieldVal })
      }
    })
    APPRAISAL_ACTIONS.putVehicleAppraisalTradeAdjustmentFields(
      appraisalInfo.task_id,
      adjustmentFields,
    ).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setShowFeedbackForm(false)
        toast('Submitted Trade Adjustment')
      } else {
        toast('Failed to Submit Trade Adjustment', {
          className: 'appraisal-display-error-toast',
          autoClose: false,
        })
      }
    })
  }
  const stripNonNumeric = val => {
    console.log(val)
    return val.replace(/[^0-9]/g, '')
  }

  const percentMSRP = (vehicle_details.current_retail_value / vehicle_details.original_msrp) * 100
  return (
    <React.Fragment>
      <div className="appraisal-document">
        {debug ? (
          <div className="appraisal-document-debug-info">
            <div className="appraisal-document-debug-api-return">
              {JSON.stringify(appraisalInfo, null, '\t')}
            </div>
            <div className="appraisal-document-debug-api-return-buttons">
              <button
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(appraisalInfo))
                }}
              >
                Copy
              </button>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        <div className="appraisal-document-vehicle-details">
          <div className="appraisal-document-vehicle-details-header">
            <div className="appraisal-document-vehicle-details-price">
              <div className="appraisal-document-vehicle-details-price-retail">
                <div className="appraisal-document-vehicle-details-price-retail-main-heading">
                  {`$${decimalFormatter.format(vehicle_details.current_retail_value)}`}
                  <button
                    className="appraisal-document-vehicle-details-price-retail-adjust"
                    onClick={() => {
                      setShowFeedbackForm(true)
                    }}
                  >
                    Edit Trade
                  </button>
                </div>
                <div className="appraisal-document-vehicle-details-price-retail-sub-heading">
                  {`iPacket Market Value - ${percentMSRP.toFixed(2)}%`}
                </div>
              </div>
              <div className="appraisal-document-vehicle-details-price-separator"></div>
              <div className="appraisal-document-vehicle-details-price-msrp">
                {`$${decimalFormatter.format(vehicle_details.original_msrp)}`}
                <div className="appraisal-document-vehicle-details-price-msrp-sub-heading">
                  Original MSRP
                </div>
              </div>
            </div>
            <div className="appraisal-document-vehicle-details-location">
              {vehicle_details.location}
            </div>
          </div>
          <div className="appraisal-document-vehicle-details-body">
            <div className="appraisal-document-vehicle-details-body-image">
              <IPacketRingLogo />
            </div>
            <div className="appraisal-document-vehicle-details-body-info">
              <div className="appraisal-document-vehicle-details-ymm">
                {`${vehicle_details.year} ${vehicle_details.make} ${vehicle_details.model}`}
              </div>
              {vehicle_details.trim ? (
                <div className="appraisal-document-vehicle-details-trim">
                  {`${vehicle_details.trim}`}
                </div>
              ) : (
                <React.Fragment />
              )}

              <div className="appraisal-document-vehicle-details-mileage">
                {`Miles: ${decimalFormatter.format(vehicle_details.mileage)}`}
              </div>
              <CarfaxDisplay
                number_of_owners={vehicle_details.number_of_owners}
                accident_history={vehicle_details.accident_history}
                vehicle_use={vehicle_details.vehicle_use}
              />
              <Link to={`/stickerpull/${vehicle_details.vin}`} target="_blank">
                View Sticker
              </Link>
            </div>
          </div>
        </div>

        <div className="appraisal-document-comparables">
          <div className="appraisal-document-comparables-title"> Market Comp Detail</div>
          <div className="appraisal-document-comparables-items">
            {appraisalComparables.map(comparable => {
              return <ComparableItem details={comparable} />
            })}
          </div>
        </div>
      </div>
      {/*This is using a custom modal because this modal needs to pass user events back to the base page*/}
      {showFeedbackForm ? (
        <div
          className="appraisal-document-modal-overlay"
          onClick={e => {
            setShowFeedbackForm(false)
          }}
        >
          <div
            className="appraisal-document-modal"
            onClick={e => {
              //Lazy Solution
              e.stopPropagation()
            }}
          >
            <div
              className="modal-exit-btn"
              onClick={e => {
                setShowFeedbackForm(false)
              }}
            >
              <CloseIcon />
            </div>
            <div className="appraisal-document-feedback-form">
              <div className="appraisal-document-feedback-form-input-container">
                <h1>
                  <b>Market Value</b>
                </h1>
                <input
                  value={marketValueField}
                  type="number"
                  onChange={e => {
                    setMarketValueField(stripNonNumeric(e.target.value))
                  }}
                />
              </div>
              <hr />
              <div className="appraisal-document-feedback-form-title">Trade-In Reductions (-)</div>
              {tradeAdjustmentFields &&
                tradeAdjustmentFields.map((field, index) => {
                  if (field.type === 'subtraction') {
                    return (
                      <div
                        className="appraisal-document-feedback-form-input-container"
                        key={field.label}
                      >
                        <h1>{field.label}</h1>
                        <input
                          value={field.value}
                          type="number"
                          onChange={e => {
                            updateTradeAdjustmentField(index, stripNonNumeric(e.target.value))
                          }}
                        />
                      </div>
                    )
                  }
                })}

              <div className="appraisal-document-feedback-form-title">Trade-In Adjustments (+)</div>
              {tradeAdjustmentFields &&
                tradeAdjustmentFields.map((field, index) => {
                  if (field.type === 'addition') {
                    return (
                      <div
                        className="appraisal-document-feedback-form-input-container"
                        key={field.label}
                      >
                        <h1>{field.label}</h1>
                        <input
                          value={field.value}
                          type="number"
                          onChange={e => {
                            updateTradeAdjustmentField(index, stripNonNumeric(e.target.value))
                          }}
                        />
                      </div>
                    )
                  }
                })}
              {/*
                  <div className="appraisal-document-feedback-form-input-container">
                    <h1>Reconditioning Cost</h1>
                    <input
                      value={reconCostField}
                      type='number'
                      onChange={e=>{
                        setReconCostField(stripNonNumeric(e.target.value))
                      }}
                    />
                  </div>
                  <div className="appraisal-document-feedback-form-input-container">
                    <h1>Body Work Cost</h1>
                    <input
                      value={bodyWorkCostField}
                      type='number'
                      onChange={e=>{
                        setBodyWorkCostField(stripNonNumeric(e.target.value))
                      }}
                    />
                  </div>
                  <div className="appraisal-document-feedback-form-input-container">
                    <h1>Tire Cost</h1>
                    <input
                      value={tireCostField}
                      type='number'
                      onChange={e=>{
                        setTireCostField(stripNonNumeric(e.target.value))
                      }}
                    />
                  </div>
                  <div className="appraisal-document-feedback-form-input-container">
                    <h1>Certification Cost</h1>
                    <input
                      value={certificationCostField}
                      type='number'
                      onChange={e=>{
                        setCertificationCostField(stripNonNumeric(e.target.value))
                      }}
                    />
                  </div>
                  <div className="appraisal-document-feedback-form-input-container">
                    <h1>Profit</h1>
                    <input
                      value={profitField}
                      type='number'
                      onChange={e=>{
                        setProfitField(stripNonNumeric(e.target.value))
                      }}
                    />
                  </div>
                */}
              <div className="appraisal-document-feedback-form-trade-in-value-container">
                <h1>Trade-In Value</h1>
                <div className="appraisal-document-feedback-form-trade-in-value">{`$${decimalFormatter.format(
                  tradeInValueField,
                )}`}</div>
              </div>
              <button
                disabled={marketValueField === tradeInValueField}
                className="appraisal-document-feedback-form-submit"
                onClick={() => {
                  submitTradeAdjustmentFields()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  )
  if (vehicle_details) {
  }
}
function ComparableItem({ details }) {
  const {
    vin,
    year,
    make,
    model,
    trim,
    number_of_owners,
    accident_history,
    vehicle_use,
    location,
    mileage,
    sales_price,
    original_msrp,
    percent_msrp,
  } = details
  return (
    <div className="appraisal-document-comparables-item">
      <div className="appraisal-document-comparables-item-field">
        <div>
          <div className="appraisal-document-comparables-item-vin">{vin}</div>
          <div className="appraisal-document-comparables-item-ymmt">{`${year} ${make} ${model} ${trim}`}</div>
        </div>
      </div>
      <div className="appraisal-document-comparables-item-field">
        <CarfaxDisplay
          number_of_owners={number_of_owners}
          accident_history={accident_history}
          vehicle_use={vehicle_use}
        />
      </div>
      <div className="appraisal-document-comparables-item-field">
        <div className="appraisal-document-comparables-item-location">{location}</div>
      </div>
      <div className="appraisal-document-comparables-item-field">
        {`${decimalFormatter.format(mileage)} mi.`}
      </div>
      <div className="appraisal-document-comparables-item-field">
        <Link to={`/stickerpull/${vin}`} target="_blank">
          View
          <br />
          Sticker
        </Link>
      </div>
      <div className="appraisal-document-comparables-item-field">
        <div className="appraisal-document-comparables-item-price">
          <div className="appraisal-document-comparables-item-price-retail">
            <div className="appraisal-document-comparables-item-price-title">{`SALE - ${percent_msrp}%`}</div>
            <div className="appraisal-document-comparables-item-price-value">{`$${decimalFormatter.format(
              sales_price,
            )}`}</div>
          </div>
          <div className="appraisal-document-comparables-item-price-separator"></div>
          <div className="appraisal-document-comparables-item-price-msrp">
            <div className="appraisal-document-comparables-item-price-title">{`MSRP`}</div>
            <div className="appraisal-document-comparables-item-price-value">{`$${decimalFormatter.format(
              original_msrp,
            )}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CarfaxDisplay({ number_of_owners, accident_history, vehicle_use }) {
  return (
    <div className="appraisal-document-carfax">
      <div className="appraisal-document-carfax-title">CARFAX</div>
      <div className="appraisal-document-carfax-owners">
        {number_of_owners ? (
          <React.Fragment>
            <PersonIcon />
            {number_of_owners}
          </React.Fragment>
        ) : (
          'N/A'
        )}
      </div>
      <div
        className={`appraisal-document-carfax-accidents ${
          accident_history === 'Accident Reported'
            ? 'appraisal-document-carfax-accidents-crash'
            : ''
        }`}
        title={`${accident_history}`}
      >
        {accident_history === 'Accident Reported' ? <CarCrashIcon /> : <CarClearIcon />}
      </div>
      <div className="appraisal-document-carfax-use">{vehicle_use ? vehicle_use : 'N/A'}</div>
    </div>
  )
}
