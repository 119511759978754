import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'
import VALUE_TYPES from '../_constants/valueTypes'

export function updateFormFields(fields) {
  return function(dispatch) {
    dispatch({
      type: VALUE_TYPES.VALUE_SET_NEW_APPRAISAL_FIELDS,
      fields,
    })
  }
}

export function clearFormFields(fields) {
  return function(dispatch) {
    dispatch({
      type: VALUE_TYPES.VALUE_CLEAR_NEW_APPRAISAL_FIELDS,
    })
  }
}

export function setAppraisalInfo(appraisalInfo) {
  return function(dispatch) {
    dispatch({
      type: VALUE_TYPES.VALUE_SET_APPRAISAL_INFO,
      appraisalInfo,
    })
  }
}

export function getTeamMembers(store_id, search, page, items_per_page) {
  let pathParameters = `${store_id}/team-members/?page=${page}&search=${search}`

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminTeamMembersGetAPIURL}${pathParameters}`, {
    method: 'GET',
    headers,
  })
}

export function getCustomers(store_id, search, page, items_per_page) {
  let pathParameters = `?store_id=${store_id}&page=${page}&search=${search}`

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactSearchAPIURL}${pathParameters}`, {
    method: 'GET',
    headers,
  })
}

export function getAccidentHistories() {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAppraisalListAccidentHistories()}`, {
    method: 'GET',
    headers,
  })
}

export function createAppraisal(appraisalFields) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAppraisal()}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...appraisalFields,
    }),
  })
}

export function listAppraisals(page, search) {
  let pathParameters = `?page=${page}&page_size=${15}${
    search && search.length ? `&search=${search}` : ''
  }`
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAppraisal()}${pathParameters}`, {
    method: 'GET',
    headers,
  })
}

export function getAppraisalStatus(appraisal_id) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoGetAppraisalStatus(appraisal_id)}`, {
    method: 'GET',
    headers,
  })
}

export function getAppraisal(appraisal_id) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoGetAppraisal(appraisal_id)}`, {
    method: 'GET',
    headers,
  })
}

export function updateAppraisal(appraisal_id, appraisal) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoUpdateAppraisal(appraisal_id)}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify({
      ...appraisal,
    }),
  })
}

export function listAppraisalComparableVehicles(appraisal_id) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoListAppraisalComparableVehicles(appraisal_id)}`, {
    method: 'GET',
    headers,
  })
}

export function createStoreTradeTemplate(store_id, templateFields) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreTradeAdjustmentTemplate(store_id)}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      ...templateFields,
    }),
  })
}

export function getStoreTradeTemplate(store_id) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreTradeAdjustmentTemplate(store_id)}`, {
    method: 'GET',
    headers,
  })
}

export function updateStoreTradeTemplate(store_id, templateFields) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreTradeAdjustmentTemplate(store_id)}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify({
      ...templateFields,
    }),
  })
}

export default {
  dispatchActions: {
    updateFormFields,
    clearFormFields,
    setAppraisalInfo,
  },
  getTeamMembers,
  getCustomers,
  getAccidentHistories,
  createAppraisal,
  listAppraisals,
  getAppraisalStatus,
  getAppraisal,
  updateAppraisal,
  listAppraisalComparableVehicles,
  createStoreTradeTemplate,
  getStoreTradeTemplate,
  updateStoreTradeTemplate,
}
