// general: 2nd level applicaiton start file - for routing.

// Note: please us the 'PrivateRoute' function for any pages that need tokens.
// example setup of a new 'Protected' rout ->
// <PrivateRouteRedirect access_token={access_token} path="/<path_name_here>" component={<component_here>} />

// https://tylermcginnis.com/react-router-protected-routes-authentication/

// hot reloading!
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
// @flow
import React, { Component } from 'react'
// node packages
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

// core files
import Helper from './web/core/helpers/platformhelper'
import pageTitleHelper from './web/core/helpers/pageTitleHelper'
// components
import LoginPage from './web/content/login/loginPage'
import LogoutPage from './web/content/logout/logoutPage'
import AuthPage from './web/content/auth/authPage'
import PageNotFound from './web/content/nopage'
import TOSAgreement from './web/content/tosagreement'
import WhitescreenError from './web/content/whitescreenError.js'
import Footer from './web/content/layouts/footer'
import PriceChange from './web/content/pricechange/pricechange'
import TemporaryPasswordReset from './web/content/required/temporarypasswordreset'
import AppSkin from './styles/appSkin'
import NotificationBanner from './web/content/notificationBanner/notificationBanner'
import Unsubscribe from './web/content/unsubscribe/unsubscribe'
// protected components
// --- top
import Header from './web/content/layouts/header'
// --- dashboard
import Dashboard from './web/content/dashboard/dashboard'
// --- customers
import Customers from './web/content/customers/customers'
import CustomerDash from './web/content/customers/customersdash'
// --- admin
import AdminSettings from './web/content/admin_settings/adminsettings'
// --- user settings
import AccountSettings from './web/content/accountsettings/accountsettings'
// --- reports
import Reports from './web/content/reports/reports'
// --- tools
import Tools from './web/content/tools/tools'
// --- stickerPull
import StickerPullParent from './web/content/stickerpull/stickerpull'

import ArcPage from './web/content/arc/arcpage'
// --- packets
import Inventory from './web/content/inventory/inventory.js'
import ArchivedInventory from './web/content/archivedinventory/archivedinventory'

// --- packet view
import PacketView from './web/content/packetview/packetview'

import StaffTools from './web/content/stafftools/stafftools'

// --- react-admin-app
import Overview from './web/content/reactadminapp/overview'
import Search from './web/content/reactadminapp/search'
import VehicleHistory from './web/content/reactadminapp/vehicleHistory'
// import Overview from "./web/content/reactadminapp/components/overview";

import BulkUserUpload from './web/content/stafftools/components/bulkuserupload'
import { TEMPORARY_PASSWORD } from './web/core/_constants/settingsTypes'

import Appraisal from './web/content/value/appraisal'
import Value from './web/content/value/value'
// styles
import './web/content/styles/universalstyles.scss'

type Props = {} // none.

function PrivateRouteRedirect({ component: Component, access_token, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        access_token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
class MainRouterComponent extends Component {
  render() {
    const {
      tosAcceptance,
      required_action,
      access_token,
      userLoginError,
      showAppraisal,
    } = this.props
    pageTitleHelper()
    return (
      <AppSkin>
        <div className="site-router" style={{ height: '100%' }}>
          {access_token ? <Header /> : null}

          <Switch>
            <Route path="/pricechange/:jwt_string" component={PriceChange} />
            <Route
              path="/unsubscribe/:customer_id/:jwt_string"
              render={props => <Unsubscribe match={props.match} />}
            />
            <Route path="/logout" component={LogoutPage} />
            {userLoginError !== null && (
              <Route
                path="/*"
                render={() => {
                  return <WhitescreenError error={userLoginError} />
                }}
              />
            )}
            {!access_token ? <Route exact path="/" component={LoginPage} /> : null}
            {!access_token ? (
              <Route
                exact
                path="/require-login"
                render={() => <LoginPage require_login={true} />}
              />
            ) : null}
            {!access_token ? <Route exact path="/auth" component={AuthPage} /> : null}
            {!access_token ? <Redirect to="/" /> : null}

            {access_token && required_action === TEMPORARY_PASSWORD && (
              <Route path="/*" component={TemporaryPasswordReset} />
            )}
            {access_token && !required_action && !tosAcceptance && (
              <Route path="/*" component={TOSAgreement} />
            )}

            <PrivateRouteRedirect
              access_token={access_token}
              path="/dashboard"
              component={Dashboard}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/dashboard/accountsettings/active"
              component={Dashboard}
            />

            <PrivateRouteRedirect
              access_token={access_token}
              path="/customers"
              component={Customers}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/customerinfo/:customer_id/alerts"
              component={CustomerDash}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/customerinfo/:customer_id/info"
              component={CustomerDash}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/customerinfo/:customer_id/history"
              component={CustomerDash}
            />

            <PrivateRouteRedirect
              access_token={access_token}
              path="/adminsettings/basics"
              component={AdminSettings}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/adminsettings/teammembers"
              component={AdminSettings}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/adminsettings/emailtemplates"
              component={AdminSettings}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/adminsettings/reporting"
              component={AdminSettings}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/accountsettings"
              component={AccountSettings}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/packets"
              component={Inventory}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/archived-inventory"
              component={ArchivedInventory}
            />

            <PrivateRouteRedirect access_token={access_token} path="/reports" component={Reports} />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/reports/activity-report"
              component={Reports}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/reports/status-report"
              component={Reports}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/reports/user-upload-report"
              component={Reports}
            />

            <PrivateRouteRedirect
              access_token={access_token}
              path="/tools/document-upload"
              component={Tools}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/tools/finance-tables"
              component={Tools}
            />

            <PrivateRouteRedirect
              access_token={access_token}
              path="/packet/view/:vehicle_id"
              component={PacketView}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/packet/activity/:vehicle_id"
              component={PacketView}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/packet/print/:vehicle_id"
              component={PacketView}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/packet/timeline/:vehicle_id"
              component={PacketView}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/packet/report-issue/:vehicle_id"
              component={PacketView}
            />

            <PrivateRouteRedirect
              access_token={access_token}
              path="/stickerpull"
              component={StickerPullParent}
            />

            <PrivateRouteRedirect
              access_token={access_token}
              path="/arc/:vin"
              component={ArcPage}
            />

            {/* <PrivateRouteRedirect access_token={access_token} path="/staff-tools" component={StaffTools} /> */}

            <PrivateRouteRedirect
              access_token={access_token}
              key={Date.now()}
              path="/staff-tools/overview/"
              component={Overview}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              key={Date.now()}
              path="/staff-tools/overview/:store_id"
              component={Overview}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/staff-tools/search"
              component={Search}
            />
            <PrivateRouteRedirect
              access_token={access_token}
              path="/staff-tools/vehicle-history-search"
              component={VehicleHistory}
            />

            <PrivateRouteRedirect
              access_token={access_token}
              path="/staff-tools/bulk-upload"
              component={BulkUserUpload}
            />

            {showAppraisal ? (
              <PrivateRouteRedirect
                access_token={access_token}
                path="/value/:section"
                component={Value}
              />
            ) : null}

            <Redirect to="/packets" />
          </Switch>

          {access_token ? <Footer /> : null}
          <NotificationBanner />
        </div>
      </AppSkin>
    )
  }
}
// export default hot(MainRouterComponent)

const mapStateToProps = state => ({
  tosAcceptance: state.userSettings.tosAcceptance,
  required_action: state.userSettings.required_action,
  access_token: state.authentication.access_token,
  userLoginError: state.userSettings.userLoginError,
  showAppraisal:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.appraisal_tool_enabled,
})
const MainRouter = hot(MainRouterComponent)
export default withRouter(connect(mapStateToProps, null)(MainRouter))
