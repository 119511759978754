import React from 'react'

const IPacketValueIcon = props => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 555 99">
    <path
      class="st1"
      d="M208.2,41.1c-6.5-3-16.3-1.2-21.8,4,1.2,1.5,2.4,2.9,3.3,3.9,3-1.2,5.5-2.7,8.1-3.3,6.7-1.5,11.2,2,10.8,8.4-1.8,0-3.6,0-5.5,0-4.8.3-9.6.6-13.8,3.1-6.7,3.9-6.7,13.3-.1,17.4,4.4,2.7,14,3.3,18.7-2.6.2-.3.5-.5,1-1,.2,1.3.2,2.4.5,3.4.1.5.7,1.1,1.1,1.1,1.8.1,3.5,0,5,0,0-8.1.2-16,0-23.8-.1-4.9-2.6-8.7-7.2-10.8h0ZM195.9,70.8c-2.1-.5-3.8-1.7-4.2-4-.5-2.6.3-4.3,2.7-5.6,1.5-.8,3.2-1.3,4.8-1.5,3.1-.4,6.2-.5,9.4-.8.7,8.6-5,13.8-12.8,11.9h0Z"
    />
    <path class="st1" d="M131.9,75.5h6.7v-34.8h-6.7v34.8h0Z" />
    <path
      class="st1"
      d="M135.4,23.6c-2.8,0-4.7,1.8-4.7,4.4s1.8,4.4,4.5,4.4c2.9,0,4.7-1.7,4.7-4.4,0-2.6-1.8-4.4-4.5-4.5h0Z"
    />
    <path
      class="st1"
      d="M174.4,24.7c-1.4-.4-2.9-.9-4.4-.9-6.7-.2-13.5-.2-20.2-.3-.1,0-.2.1-.4.3v51.8h7.2v-22.8c3.3,0,6.4.2,9.4,0,3-.3,6.1-.6,9-1.6,6.4-2.1,9.1-6.8,8.9-14.3-.1-5.8-3.6-10.2-9.5-12.1h0ZM171.1,45.5c-4.7,1.6-9.5,1-14.5,1.1v-16.3h11.5c.8,0,1.6.2,2.4.3,3.7.6,5.8,3.5,6,6.8.1,4.3-1.4,6.8-5.4,8.1h0Z"
    />
    <path
      class="st1"
      d="M249.5,67.9c-5.9,5.2-14.9,3.2-17.8-4.1-.9-2.1-1.1-4.6-1.1-7,.2-6.7,5.5-11.6,12-11.2,3,.2,5.4,1.6,7.4,3.9,1.6-1.3,3.2-2.6,4.9-4-3.7-5.2-12.3-7.3-19.5-5-7.8,2.5-12.3,9.1-12.2,17.8,0,8.4,4.8,15,12.4,17.3,7.5,2.3,16,.3,19.5-4.7-1.5-1.3-3-2.7-4.5-4.1-.4.4-.7.7-1,.9h0Z"
    />
    <path
      class="st1"
      d="M307.8,40.1c-11.8,2.4-16.7,14.4-13,25,3,8.7,11.3,12.8,21.3,11.2,4.6-.7,8.3-3,11.2-6.9-1.7-1.4-3.3-2.6-4.8-3.9-5.2,5.3-10.5,6.6-15.7,4-3.7-1.9-6.1-5.9-5.6-9.2h27.5c0-1.4,0-2.5,0-3.7-.3-11.8-9.3-18.8-20.9-16.5h0ZM301,54.9c.1-3.5,1.8-6,4.5-7.8,3.7-2.4,7.7-2.7,11.6-.5,3.1,1.8,4.3,4.7,4.4,8.3h-20.6,0Z"
    />
    <path
      class="st1"
      d="M344.4,30.8h-6.9v10h-6.5v5.8h6.6v2.4c0,5.9,0,11.7,0,17.5,0,3.9,1.4,7.3,5.3,8.9,3.5,1.5,7.2,1,10.6,0v-5.7c-.8.2-1.4.4-2,.5-4.7,1-6.9-.7-7-5.5-.1-4.8,0-9.5,0-14.3v-4h9.1v-5.7h-9.1v-9.9h0Z"
    />
    <path
      class="st1"
      d="M290.6,40.6c-2.3,0-4.6,0-7,0s-1.4.1-2.1.8c-4.2,4.2-9,8.9-13.1,13.1-.3.4-.7.6-1.4,1.2V23.3h-6.7v52.3h6.9v-18.1c.9,1,1.4,1.6,2,2.2,4.3,4.9,8.6,9.9,12.9,14.8.4.5.9,1.1,1.4,1.1,3,.1,6,0,9.4,0-6.1-6.6-11.9-12.8-17.5-18.9,5.2-5.5,10.3-10.8,15.4-16.2h0Z"
    />
    <path
      class="st1"
      d="M49.3,1.4C22.8,1.3,1.1,22.8,1,49.2c0,26.7,21.4,48.3,47.9,48.4,26.6,0,48.1-21.5,48.1-48.2,0-26.4-21.5-48-47.8-48.1h0ZM49.1,91c-22.9,0-41.5-18.6-41.5-41.5,0-22.9,18.6-41.6,41.4-41.6,22.9,0,41.5,18.6,41.5,41.5,0,22.9-18.6,41.6-41.4,41.6h0Z"
    />
    <path
      class="st1"
      d="M74.9,34.3h-26.1c-.5,0-.9-.4-.9-.9v-1.9c0-.6-.5-1.1-1.1-1.1h-18.3c-.6,0-1.1.5-1.1,1.1v1.9c0,.5-.4.9-.9.9h-2.6c-.1,0-.3,0-.4,0,0,0-.2,0-.2.1,0,0-.2.1-.2.1,0,0-.1.1-.2.2,0,.1-.2.2-.2.3,0,0,0,0,0,0,0,.2-.1.4-.1.7l1.3,32c0,.9.8,1.6,1.8,1.6h47.8c.9,0,1.7-.7,1.8-1.6l1.7-32c0-.9-.7-1.7-1.6-1.7h0Z"
    />
    <path class="st1" d="M390.8,75.6h-12.6l-19.7-52h14.1l12,36.9h.3l12-36.9h13.9l-20.1,52h0Z" />
    <path
      class="st1"
      d="M410.2,44c4.3-4,10.4-6.2,16.3-6.2,12.3,0,16.8,6,16.8,19.3v18.5h-11v-3.9h-.2c-1.8,3-6,4.8-10.4,4.8s-13.3-2.9-13.3-11.5,12.9-12.4,23.5-12.4v-.6c0-3.6-2.9-5.3-6.6-5.3s-6.8,1.7-9,3.8l-6.1-6.5h0ZM432.3,59.7h-1.5c-5.3,0-11.2.7-11.2,5s2.8,3.8,5.2,3.8c4.8,0,7.6-2.9,7.6-7.5v-1.2Z"
    />
    <path class="st1" d="M451.7,75.6V23.1h12.2v52.5h-12.2Z" />
    <path
      class="st1"
      d="M496.8,75.6v-5.1h-.2c-1.7,3.2-5.9,6.1-11,6.1-9.8,0-13.3-7.6-13.3-15v-22.6h12.1v19.9c0,4.1,1,7.8,5.4,7.8s6.5-3.7,6.5-7.9v-19.8h12.1v36.6h-11.6Z"
    />
    <path
      class="st1"
      d="M553.4,60.7h-26.5c.4,4,4.4,6.9,8.8,6.9s6.5-1.6,8.1-3.8l8.4,5.3c-3.5,5-9.2,7.8-16.6,7.8-11,0-20.1-7-20.1-19.3s8.7-19.6,19.8-19.6,18.4,7.4,18.4,19.9,0,2,0,2.9h0ZM542.3,53.2c0-3.8-2.4-7-7.2-7s-8,3.2-8.3,7h15.5Z"
    />
  </svg>
)
export default IPacketValueIcon
